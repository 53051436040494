@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.digitalCatalogueBanner {
    display: flex;
    background: #cf499a;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    grid-row: 3 / 3;
    align-self: flex-end;
    padding: 0 7vw;
    z-index: 0;

    @include mobile {
        padding: 0;
        width: 100vw;
    }
}

.digitalCatalogue {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 5rem;
    align-items: center;
}

.digitalCatalogue p {
    margin: 0;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: var(--ljr-paragraph);

    @include tablet {
        font-size: 1.25rem;
    }

    @include mobile {
        font-size: 1.17rem;
    }
}

.digitalCatalogue p a {
    color: #ffffff;
}

.digitalCatalogueIcon {
    margin-right: 0.5rem;
}

@media (max-width: 641px) {
    .digitalCatalogue {
        justify-content: center;
    }
}
