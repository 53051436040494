:root {
    --table-secondary-color: var(--secondary-light-color);
}

table {
    tr:nth-child(even),
    thead tr {
        //background-color: var(--table-secondary-color);
    }

    thead tr {
        //border-bottom: 1px solid var(--secondary-dark-color);
    }

    td,
    th {
        padding: 10px;
        text-align: left;
        min-width: 12rem;

        @include mobile {
            min-width: 14rem;
        }
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: scroll;
}
