@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Breadcrumbs {
    padding: 1.6rem 0;

    @include before-desktop {
        padding: 1rem 0 0 0;
    }

    &-List {
        display: flex;
        justify-content: center;
        gap: 0.75rem;

        @media (min-width: 960px) {
            justify-content: start;
        }
    }
}
