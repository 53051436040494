ul,
ol {
    ul,
    ol {
        counter-reset: list;
    }
}

li {
    padding-left: 0;

    @include mobile {
        padding-left: 0;
    }

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        padding-right: 0;

        ol > & {
            content: counter(list) '.';
        }

        &,
        ul > & {
            content: none;
        }
    }
}
