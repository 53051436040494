@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Breadcrumb {
    display: flex;
    gap: 0.5rem;

    &:not(:last-of-type) {
        &:after {
            content: '\003E';
        }
    }

    &:last-of-type {
        font-weight: bold;
        margin-bottom: 6px;
    }
}
