@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --menu-background-color: #f4f4f4;
    --menu-desktop-background-color: rgba(122, 122, 122, .44);
    --menu-desktop-height: 11rem;
    --menu-item-figure-background: var(--secondary-base-color);
    --menu-item-hover-color: var(--primary-base-color);
    --menu-second-level-color: #adadad;
    --menu-item-sale-color: #ef183f;
}

@mixin CategoryTitle {
    padding-bottom: 1rem;
    border-bottom: solid rgba(0, 0, 0, 0.10) 2px;
    margin: 1rem;
}

@mixin subcategory-visible {
    pointer-events: all;
    z-index: 10;
    opacity: 1;
    transform: translateX(0);
}

.Menu {
    &-MenuWrapper {
        padding: 1.2rem;
        text-align: -webkit-center;
        grid-row: 2 / 2;
        background-color: #F4F4F4;
        border-top: solid 1px #e5e5e5;

        @include before-desktop {
            padding: 0;
        }

        @include desktop {
            padding: 0;
        }

        li {
            @include mobile {
                padding-left: 0;
            }

            &::before {
                content: none;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-Overlay {
        backdrop-filter: blur(5px);
        height: 100vh;
        width: 100%;
        position: absolute;
        z-index: -1;
        top: -3px;
        left: 0;
        opacity: 0;
        transition: opacity 200ms ease-in;
        will-change: transition;

        &_isVisible {
            opacity: 1;
        }
    }

    &-MainCategories {
        @include before-desktop {
            display: flex;
            flex-direction: column;
        }

        @include desktop {
            display: grid;
            z-index: 1;
            max-width: 100vw;
            margin: 0;
            pointer-events: none;
            justify-items: center;
            grid-template-rows: auto auto;
            height: 100%;
        }
    }

    &-ItemList {
        display: grid;

        @include desktop {
            display: flex;
            height: 4rem;
        }

        &_type {
            &_main {
                @include desktop {
                    pointer-events: auto;
                    width: min-content;
                }

                @include before-desktop {
                    order: 2;
                }
            }

            &_subcategory {
                background: var(--menu-background-color);
                grid-auto-rows: max-content;
                padding: 0 1.2rem;
                height: fit-content;

                @include mobile {
                    padding: 0 1.4rem;
                    grid-template-columns: 1fr;
                    grid-template-rows: auto auto auto;
                }

                @include desktop {
                    display: grid;
                    padding: 0;
                    width: 100%;
                    grid-template-rows: auto auto;
                    grid-template-columns: 7vw 1fr 1fr 1fr 1fr 7vw;
                    //flex-wrap: wrap;
                }
            }
        }
    }

    &-ItemCaption {
        &_type {
            &_main {
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 1.2;
                text-align: left;
                text-transform: none;
                padding-left: 1rem;

                @include before-desktop {
                    display: flex;
                    justify-content: space-between;
                }

                @include mobile {
                    font-size: 2.1rem;
                }

                @include desktop {
                    font-size: var(--ljr-paragraph);
                    font-weight: normal;
                }
            }

            &_subcategory {
                font-size: 1.2rem;
                font-weight: normal;
                padding: 1.2rem 0;

                @include mobile {
                    font-size: 1.4rem;
                    padding: 1.4rem 0;
                }

                @include desktop {
                    padding-left: 0;
                }
            }
        }

        &_isLogo {
            font-size: 1.1rem;
        }

        &_isBanner {
            --button-background: var(--homepage-button-background);
            --button-border: transparent;
            --button-color: var(--homepage-button-color);
            --button-hover-background: var(--homepage-button-color);
            --button-hover-border: transparent;
            --button-hover-color: var(--homepage-button-background);
            --button-padding: 9px 49px;

            bottom: 20px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }
    }

    &-ItemFigure {
        align-items: center;
        display: grid;

        &_type {
            &_main {
                padding-left: 30px;
                text-transform: uppercase;

                @include before-desktop {
                    grid-auto-flow: column;
                    height: 50px;
                    padding-left: 0;
                }

                @include desktop {
                    height: 100%;
                    padding-left: 0;
                    z-index: 20;
                }
            }
        }

        &_isBanner {
            @include desktop {
                overflow: hidden;
                width: 200px;
            }
        }

        &_isLogo {
            display: flex;

            .Image-Image {
                object-fit: cover;
            }
        }

        &_isHideOnDesktop {
            @include desktop {
                display: none;
            }
        }
    }

    &-Item {
        @include before-desktop {
            margin-top: .6rem;
            padding-left: 0;
        }

        @include mobile {
            margin-top: .7rem;
            padding-left: 0;
        }

        @include desktop {
            margin-bottom: 0;
            padding: 0 2.5rem;
        }

        @include tablet-portrait {
            padding: 0 .5rem;
        }

        &:first-child {
            margin-top: 0;

            @include desktop {
                padding-left: 0;
            }
        }

        button {
            width: 100%;
        }

        &_isHideOnDesktop {
            @include desktop {
                display: none;
            }
        }
    }

    &-SubCategoriesWrapper {
        position: absolute;
        top: 4rem;
        pointer-events: none;
        width: 100%;
        height: fit-content;

        @include tablet {
            top: var(--header-nav-height);
            left: 0;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: calc(var(--header-nav-height) * -1);
        }

        &_isVisible {
            pointer-events: all;
        }
    }

    &-SubCategoriesWrapperInner {
        background-color: var(--overlay-background);
        transition: clip-path 200ms ease-in;
        will-change: clip-path;
        clip-path: inset(0 0 100% 0);
        margin: auto;
        padding: 0;
        overflow-y: auto;
        height: fit-content;

        &_isVisible {
            clip-path: inset(0);
        }
    }

    &-SubCategories,
    &-Promotion {
        max-width: 100%;
        background-color: #F4F4F4;
    }

    &-Promotion {
        order: 3;
    }

    &-SubCategories {
        padding: 0;
        background-color: #f4f4f4;
        height: fit-content;

        @include mobile {
            padding: 40px 55px 60px 1.4rem;
        }
    }

    &-SubMenu {
        background-color: var(--overlay-background);
        opacity: 0;
        pointer-events: none;
        touch-action: pan-y;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
        @include subcategory-visible;

        @include before-desktop {
            height: 100%;
            left: 0;
            padding-top: 15rem;
            position: fixed;
            top: 0;
            width: 100%;
        }

        @include desktop {
            background: transparent;
            transform: translateX(0);
            transition-duration: 0ms;
            width: 100%;
            height: fit-content;
        }

        &_isVisible {
            @include subcategory-visible;
            @include mobile {
                height: 100%;
                overflow-y: scroll;
            }

            .Menu-Link {
                @include desktop {
                    padding: 0;
                }
            }
        }

        .Menu-Link {
            @include desktop {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &-SubItemWrapper {
        flex-basis: 200px;
        background-color: #f4f4f4;

        @include mobile {
            grid-row: 2 / 3;
        }

        @include desktop {
            margin: 0;
        }

        &_isBanner {
            flex-grow: 1;
            flex-basis: 400px;
        }

        &_isHideOnDesktop {
            @include desktop {
                display: none;
            }
        }

        > .Menu-Link {
            display: block;

            @include CategoryTitle;
            @include desktop {
                color: var(--ljr-primary-black-100);
                font-size: 1.4rem;
                text-align: start;
            }
        }
    }

    &-SubLevelDesktop {
        .Menu {
            &-ItemCaption {
                font-size: var(--ljr-paragraph);
                font-weight: 400;
                text-align: start;
            }
            &-ItemList {
                display: block;
                padding: 0;

                &_isBanner {
                    display: grid;
                    grid-column-gap: 10px;
                    grid-template-columns: repeat(auto-fit, 200px);
                }
            }

            &-Link {
                //margin-top: 20px;
                padding-left: 1rem;
                display: block;
            }
        }
    }

    &-Image {
        &_isBanner {
            @include desktop {
                padding-bottom: 100%;
            }

            img {
                object-fit: cover;
            }
        }

        &_isLogo {
            height: 44px;
            margin-right: 20px;
            width: 44px;
        }

        &_type {
            &_main {
                grid-column: 2;
                height: 100%;
                justify-self: end;
                padding-bottom: 0;
            }

            &_subcategory {
                border-radius: 50%;
                height: 3.84rem;
                padding-bottom: 0;
                width: 3.84rem;

                @include mobile {
                    height: 4.48rem;
                    width: 4.48rem;
                }
            }
        }
    }

    &-Link {
        color: inherit;
        white-space: nowrap;
        padding-left: 0;

        @include before-desktop {
            padding: 1.2rem 0;
        }

        @include mobile {
            padding: 1.4rem 0;
        }

        &:hover,
        &_isHovered,
        &:focus {
            color: var(--menu-item-hover-color);
            text-decoration: none;
        }

        &-SaleLink {
            color: var(--menu-item-sale-color);

            h4 {
                text-transform: uppercase;
            }
        }

        &-CatalogueLink {
            color: #cf499a;

            h4 {
                text-transform: uppercase;
            }
        }
    }

    &-Link + &-Link {
        margin-left: 0;
    }

    .Image {
        background: none;
    }

    &-CompareLinkWrapper {
        text-transform: uppercase;
        order: 1;
    }

    &-CompareLink {
        color: var(--body-content-color);

        &:hover {
            text-decoration: none;
        }
    }

    &-MobileContact {
        order: 3;
        padding: 2rem;

        .image {
            height: 90px;
            width: auto;
            margin-bottom: 1rem;
        }

        p {
            margin: 0.5rem 0;
        }

        .spacer {
            margin-left: 0;
        }
    }
}

.shopColumn {
    p {
        margin: 0;
    }

    background-color: rgba(196, 196, 196, 0.2);
    grid-row: 1 / 1;
    grid-column: 2 / 3;

    @include before-desktop {
        order: 2;
        margin: 0.5rem 0;
    }
}

.categoryTitle {
    padding-bottom: 1rem;
    border-bottom: solid rgba(0, 0, 0, 0.10) 2px;
    margin: 1rem;
    text-align: start;

    @include before-desktop {
        display: none;
    }
}

.listItems {
    margin: 1rem;
}

.container {
    display: flex;
    flex-direction: row;
    margin: 1.5rem 0;
    min-height: 3rem;
    align-items: center;
}

.imageCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    min-height: 1.5rem;
    border-radius: 50%;
}

.itemText {
    margin-left: 2rem;
    font-size: var(--ljr-paragraph);
}

.bottomText {
    margin: 2rem 1rem;
}

.imageColumn {
    p {
        margin: 0;
    }

    background-color: var(--ljr-primary-grey-200);
    display: grid;
    grid-template-rows: 2fr 1fr;
    grid-row: 1 / 1;
    grid-column: 5 / 6;
    padding-top: 1rem;
}

.shoeGrid {
    grid-row: 1 / 1;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
    border-bottom: solid 2px rgba(0, 0, 0, 0.10);
    padding-bottom: 1rem;
}

.bottomSection {
    margin: 2rem 0;
    padding-left: 1rem;
    grid-row: 2/2;
    border-left: 3px solid #4EC9A1;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    font-size: var(--ljr-paragraph);
}

.topText {
    text-align: start;
    font-weight: 700;
}

.bottomText {
    display: flex;
    align-items: center;
}

.bottomBar {
    @include before-desktop {
        order: 2;
    }
    p {
        margin: 0;
    }

    grid-row: 2 / 2;
    grid-column: 1 / 7;
    padding: 0 7vw;
    display: flex;
    align-items: center;
    height: 6rem;
    justify-content: space-between;
    background-color: #F1F1F1;
}

.phone {
    display: flex;
    align-content: center;
    align-items: center;
}

.email {
    display: flex;
    align-content: center;
}

.promoSection {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.spacer {
    margin: 0 1rem;
}

.imageCircle {
    margin: 0 0.5rem;
    border-radius: 50%;
}

.imageTitle {
    margin: 0 0.5rem;
}

.promoLink {
    margin: 0 0.5rem;
}

.blockColumn {
    grid-column: 1 / 2;
}
