@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.promoBar {
    display: flex;
    background: #E1EDE4;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    grid-row: 2 / 3;
    align-self: flex-end;
    padding: 0 7vw;
    z-index: 0;

    @include mobile {
        padding: 0;
        width: 100vw;
    }
}

.promo {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 5rem;
    align-items: center;
}

.promo p {
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: var(--ljr-paragraph);

    @include tablet {
        font-size: 1.25rem;
    }

    @include mobile {
        font-size: 1.17rem;
    }
}

.promoIcon {
    margin-right: 0.5rem;
}

@media (max-width: 641px) {
    .promo {
        justify-content: center;
    }
}
