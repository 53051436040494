@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --footer-height: calc(var(--footer-content-height) + var(--footer-copyright-height));
    --footer-content-height: 220px;
    --footer-copyright-height: 30px;
}

.NewsletterWrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
    align-items: center;
    background-color: #e1ede4;
    min-height: 150px;
}

.NewsletterWrapper h4 {
    line-height: var(--ljr-heading-4);
}

.NewsletterWrapper .NewsletterSubscription {
    width: 400px !important;
}

.NewsletterWrapper .FieldForm-Fields {
    width: 100% !important;
}

.Footer {
    $column-count: 4;

    .ContentWrapper {
        margin: 0;
        max-width: 100%;
        padding: 1rem 7vw;
    }

    .CompanyTitle {
        margin-bottom: 1.5rem;
    }

    .Buttons {
        display: flex;

        @include mobile {
            flex-direction: column;
        }
    }

    .SignIn {
        margin-left: 2rem;
        background-color: transparent;
        color: black;
        border: none;

        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    background-color: transparent;
                }
            }
        }

        @include mobile {
            margin-left: 0;
        }
    }

    .Button {
        @include mobile {
            align-items: center;
        }
    }

    .Logo {
        height: 2rem;
        width: 8rem;
    }

    @include mobile {
        margin-bottom: 0;
    }

    &-CopyrightContentWrapper {
        background-color: var(--footer-background-color);
    }

    &-CopyrightContent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        min-height: var(--footer-copyright-height);
        padding: 10px 5vw;
        background-color: var(--footer-background-color);

        @include after-mobile {
            padding: 15px 5vw;
        }
    }

    &-Copyright {
        font-size: 1rem;
        text-align: center;

        a {
            color: inherit;
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--footer-background-color);
    }

    &-Columns {
        display: flex;
        height: 100%;
        padding: 25px 40px;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            text-align: start;
        }

        @include after-mobile {
            padding: 60px 70px;
        }

        @include tablet {
            padding: {
                left: 30px;
                right: 30px;
            };
        }
    }

    &-Column {
        &:not(:last-of-type) {
            @include mobile {
                margin-bottom: 30px;
            }

            @include after-mobile {
                padding-right: 20px;
                max-width: calc(100% / #{ $column-count - 1 });
            }
        }
    }

    &-ColumnTitle {
        text-transform: capitalize;
        font-size: var(--ljr-subtitle);

        @include mobile {
            font-size: var(--ljr-mobile-subtitle);
        }

        @include after-mobile {
            margin: 0;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        p {
            font-size: var(--ljr-paragraph);
        }

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }
    }

    &-ColumnItem {
        color: inherit;
        margin-bottom: 0.75rem;
        font-size: var(--ljr-paragraph);

        &:last-of-type {
            margin: 0;
        }

        &_type_image {
            width: 25px;

            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-left: 100px;
            }
        }
    }
}

.copyrightLogo {
    justify-self: flex-end;
}
