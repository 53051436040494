* {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-family: 'sofia-pro', serif;
    outline: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    // backface-visibility: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
label,
summary {
    display: block;
}

header,
footer,
main {
    font-size: 1.2rem;

    @include mobile {
        font-size: 1.4rem;
    }
}

main {
    min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
    max-width: 100vw;

    @include mobile {
        min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height));
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    margin-bottom: 1.44rem;

    @include mobile {
        margin-bottom: 1.68rem;
    }
}

img {
    width: 100%;
}

html {
    font-size: $font-standard-size;
}

body {
    font-family: "sofia-pro", Sans-serif, serif;
    color: $black;
    scroll-behavior: smooth;
    background-color: #fff;
}

a {
    color: $black;
    text-decoration: inherit;

    &:hover {
        cursor: pointer;
    }
}

h1 {
    font-size: var(--ljr-heading-1);
    font-weight: 800;

    @include mobile {

        font-size: var(--ljr-mobile-heading-1);
    }

    + h2,
    + h3,
    + h4 {
        margin-top: .72rem;

        @include mobile {
            margin-top: .84rem;
        }
    }
}

h2 {
    font-weight: bold;
    font-size: var(--ljr-heading-2);
    margin-bottom: 1.8rem;
    margin-top: 2.16rem;

    @include mobile {
        font-size: var(--ljr-mobile-heading-2);
        margin-bottom: 2.1rem;
        margin-top: 2.52rem;
    }

    + h3,
    + h4 {
        top: -.9rem;
        margin-top: .72rem;
        margin-bottom: .72rem;

        @include mobile {
            top: -.9rem;
            margin-top: .84rem;
            margin-bottom: .84rem;
        }
    }
}

h3 {
    font-size: var(--ljr-heading-3);
    font-weight: bold;
    margin-top: 2.16rem;
    margin-bottom: 1.8rem;

    @include mobile {
        font-size: var(--ljr-mobile-heading-3);
        margin-top: 2.52rem;
        margin-bottom: 2.1rem;
    }

    + h4 {
        top: -.9rem;
        margin-top: .72rem;
        margin-bottom: .72rem;

        @include mobile {
            top: -.9rem;
            margin-top: .84rem;
            margin-bottom: .84rem;
        }
    }
}

h4 {
    font-size: var(--ljr-heading-4);

    @include mobile {
        font-size: var(--ljr-mobile-heading-4);
        margin-bottom: 1.68rem;
    }
}
h5 {
    font-size: var(--ljr-heading-5);

    @include mobile {
        font-size: var(--ljr-mobile-heading-5);
    }
}

p,
li,
dl {
    font-size: var(--ljr-paragraph);

    @include mobile {
        font-size: var(--ljr-mobile-paragraph);
    }
}


button {
    font-size: var(--ljr-button-text);

    @include mobile {
        font-size: var(--ljr-mobile-button-text);
    }
}

li {
    list-style-type: none;
    list-style-position: inside;
    margin-bottom: 6px;

    ol & {
        counter-increment: list;

        @include mobile {
            counter-increment: list;
        }
    }

    &:last-child {
        margin-bottom: 20px;
    }
}

p {
    margin-bottom: 1.44rem;
    line-height: 1.8;

    @include mobile {
        margin-bottom: 1.68rem;
    }
}

input,
select {
    min-height: 2.4rem;
    min-width: 4.8rem;
    font-size: 1.32rem;

    @include mobile {
        min-height: 2.8rem;
        min-width: 5.6rem;
        font-size: 1.54rem;
        font-size: 1.6rem;
    }
}

select {
    border-radius: 0;
    appearance: none;
}

button {
    cursor: pointer;

    @include mobile {
        user-select: none;
    }
}

textarea {
    resize: none;

    @include mobile {
        font-size: 1.6rem;
    }
}

mark {
    margin-right: 5px;
}

iframe {
    max-width: 100%;
}

a,
input,
button,
textarea,
select,
details {
    @include mobile {
        touch-action: manipulation;
    }
}

input {
    border-radius: 0;
}
