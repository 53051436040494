:root {
    --input-type-number-width: 80px;
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;
    --primary-divider-color: #f0f0f0;
    --secondary-error-color: #fff;
    --primary-error-color: #dc6d6d;
    --secondary-success-color: #fff;
    --primary-success-color: #7fcd91;
    --secondary-info-color: #79632e;
    --primary-info-color: #ffd166;
    --body-content-color: $black;
    --footer-background-color: #f7f7f7;

    // Declare imported primary colors to change the theme's colors
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});

    //FontSizes
    /* LJ&R Font Sizes */
    --ljr-heading-1: 5rem;
    --ljr-heading-2: 4.25rem;
    --ljr-heading-3: 3.5rem;
    --ljr-heading-4: 2.5rem;
    --ljr-subtitle: 2.25rem;
    --ljr-button-text: 1.5rem;
    --ljr-paragraph: 1.5rem;

    /* LJ&R Mobile Font Sizes */
    --ljr-mobile-heading-1: 3.5rem;
    --ljr-mobile-heading-2: 2.7rem;
    --ljr-mobile-heading-3: 2.2rem;
    --ljr-mobile-heading-4: 1.7rem;
    --ljr-mobile-heading-5: 1.5rem;
    --ljr-mobile-subtitle: 1.5rem;
    --ljr-mobile-button-text: 1.3rem;
    --ljr-mobile-paragraph: 1rem;

    /* color */
    --ljr-primary-black-100: #000000;
    --ljr-primary-black-200: #191919;
    --ljr-primary-black-300: #333333;
    --ljr-primary-black-400: #4c4c4c;
    --ljr-primary-black-500: #666666;
    --ljr-primary-black-600: #7f7f7f;
    --ljr-primary-black-700: #999999;
    --ljr-primary-black-800: #b2b2b2;
    --ljr-primary-black-900: #cccccc;
    --ljr-primary-grey-100: #e5e5e5;
    --ljr-primary-grey-200: #F4F4F4;
    --ljr-primary-green-100: #E1EDE4;
    --ljr-primary-green-200: #e7f1e9;
    --ljr-primary-green-300: #edf4ef;
    --ljr-primary-green-400: #f3f8f4;
    --ljr-primary-white-100: #ffffff;
}
